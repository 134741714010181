import { getFormattedMembershipDate, getMembershipStartDate } from '@/lib/checkout/productUtils'
import { CHECKOUT_GB, CHECKOUT_US, CheckoutCountry, TermsProps } from '@/lib/checkout/types'
import { formatCurrency } from '@/lib/checkout/utils'
import { TEST_KIT_SHIPPING_TIME_GB, TEST_KIT_SHIPPING_TIME_US } from '@/lib/checkoutConstants'
import Link from 'next/link'
import { ReactElement } from 'react'

export const MembershipTierDefaultTerms = ({
  currency,
  country,
  price,
  showTitle,
  title,
  priceAfterDiscount,
  payNowAmount,
  membershipCommitmentMonths,
}: TermsProps) => {
  const membershipPriceIncludingDiscount = priceAfterDiscount ?? price
  const renewalInterval = membershipCommitmentMonths || 12
  // Both totalMinimumCharge and totalMinimumChargeDisplay can be 0, so we can't just
  // check for "falsey" values
  const totalMinimumCharge =
    payNowAmount !== undefined && payNowAmount !== null
      ? membershipPriceIncludingDiscount * renewalInterval + payNowAmount
      : undefined
  let totalMinimumChargeDisplayAmount =
    totalMinimumCharge !== undefined && totalMinimumCharge !== null
      ? formatCurrency(currency, totalMinimumCharge, true)
      : undefined
  let membershipPriceDisplayAmount = formatCurrency(currency, membershipPriceIncludingDiscount, true)
  let payNowDisplayAmount = formatCurrency(currency, payNowAmount, true)
  if (country === CHECKOUT_US) {
    totalMinimumChargeDisplayAmount += ' (plus applicable sales taxes)'
    membershipPriceDisplayAmount += ' (plus applicable sales taxes)'
    payNowDisplayAmount += ' (plus applicable sales taxes)'
  }

  const membershipStartDate = getMembershipStartDate(false)
  const membershipStartDateFormatted = getFormattedMembershipDate({
    membershipDate: membershipStartDate,
    includeYear: true,
  })
  const shippingTime = country === CHECKOUT_GB ? TEST_KIT_SHIPPING_TIME_GB : TEST_KIT_SHIPPING_TIME_US

  const cancellationAndAutoRenewalListItems: Record<CheckoutCountry, ReactElement[]> = {
    GB: [
      <li key="terms-renewal-interval" data-testid="terms-renewal-interval">
        Your membership will auto-renew for successive {renewalInterval} month periods unless you cancel it.
      </li>,
      <li key="term-cancel-cooloff-period">
        You may cancel your testing and membership in full within 14 days of receiving your kit. However, if you open
        the kit, you will only be able to cancel your membership.
      </li>,
      <li key="term-cancel-post-cooloff-period">
        If you cancel your membership after the first 14 days, your membership will not auto-renew, but it will continue
        for the {renewalInterval} months you have committed to. More details{' '}
        <Link href="/terms" target="_blank">
          here
        </Link>
        .
      </li>,
      <li key="terms-cancel-methods">
        You may cancel by emailing{' '}
        <a href="mailto:hello@joinzoe.com" target="_blank" rel="noreferrer">
          hello@joinzoe.com
        </a>{' '}
        or using the app chat. You can cancel your membership auto-renewal in the app.
      </li>,
      <li key="terms-renewal-reminder">
        We will send your reminders about auto-renewal shortly before the end of your membership.
      </li>,
    ],
    US: [
      <li key="terms-renewal-interval" data-testid="terms-renewal-interval">
        Your membership will auto-renew for successive {renewalInterval} month periods unless you cancel it.
      </li>,
      <li key="term-cancel-cooloff-period">
        You may cancel your test kit order before we have prepared it. That will also cancel your membership. After your
        order is prepared, it is non-refundable.
      </li>,
      <li key="term-cancel-post-cooloff-period">
        If you cancel auto-renewal, your membership will still continue for the {renewalInterval} months you have
        committed to. More details{' '}
        <Link href="/terms" target="_blank">
          here
        </Link>
        .
      </li>,
      <li key="terms-cancel-methods">
        You may cancel your order or membership auto-renewal by emailing{' '}
        <a href="mailto:hello@joinzoe.com" target="_blank" rel="noreferrer">
          hello@joinzoe.com
        </a>{' '}
        or by using the ZOE app. More details{' '}
        <a href="/faqs/us#cancellations-refunds" target="_blank" rel="noreferrer">
          here
        </a>
        .
      </li>,
      <li key="terms-renewal-reminder">
        We will send you reminders about auto-renewals shortly before your membership renews. We will always notify you
        in advance if we plan to increase your membership charges.{' '}
      </li>,
    ],
  }

  return (
    <>
      <div className="terms-wrapper">
        {showTitle && (
          <p className="terms-title" data-testid="membership-terms-title">
            {title}
          </p>
        )}
        <ul className="product-terms">
          <p className="terms-subtitle">Charges for next {renewalInterval} months</p>
          <li data-testid="terms-charges">
            We&apos;ll charge you {payNowDisplayAmount} today. As part of your {renewalInterval}-month membership,
            we&apos;ll also charge you {membershipPriceDisplayAmount} per month for {renewalInterval} months, starting
            on {membershipStartDateFormatted}. Minimum total charge will be {totalMinimumChargeDisplayAmount}.
          </li>
          <li>Your kit should arrive within {shippingTime}.</li>

          <p className="terms-subtitle">Cancellation and auto-renewal</p>
          {cancellationAndAutoRenewalListItems[country]}
        </ul>
      </div>
    </>
  )
}
