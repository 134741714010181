import { Product } from './types'

const FREE_TRIAL_DAYS = 30

export const productHasFreeTrial = (product: Product): boolean => {
  return product.zoeProductId.includes('FREE_TRIAL')
}

export const getMembershipStartDate = (hasFreeTrial: boolean): Date => {
  const baseDate = new Date()
  if (hasFreeTrial) {
    baseDate.setDate(baseDate.getDate() + FREE_TRIAL_DAYS)
  }
  return new Date(baseDate.setMonth(baseDate.getMonth() + 1))
}

type FormattedMembershipDateProps = {
  membershipDate: Date
  includeYear?: boolean
}

export const getFormattedMembershipDate = ({
  membershipDate,
  includeYear = false,
}: FormattedMembershipDateProps): string => {
  const options: Intl.DateTimeFormatOptions = { month: 'long', day: '2-digit' }
  if (includeYear) {
    options.year = 'numeric'
  }
  return new Intl.DateTimeFormat('en-gb', options).format(membershipDate)
}
